<template>
    <div class="wallet-main">
        <van-pull-refresh v-model="isLoading" :disabled="refreshdisabled" class="myrefreh" @refresh="onRefresh">
            <div class="main-head">
                <div class="head-section">
                    <p class="sect-price-title">{{$t("myWallet.canCarry")}}</p>
                    <p class="price-txt">
                        {{walletData.canWithdraw||0}}
                    </p>
                    <p class="Withdrawal" @click="goPath('/my/WithdrawalList')">{{$t("myWallet.promptlywithdrawal")}}</p>
                    <div class="past-records">
                        <p class="left">
                            <span class="txt">{{$t("myWallet.aggregateAmount")}} </span>
                            <span class="price" v-if="walletData.canWithdraw!=undefined">{{(walletData.canWithdraw+walletData.block+walletData.closed+walletData.finished+walletData.withdrawing).toFixed(2)}}{{$t("comonTxt.money")}}</span>
                        </p>
                        <span class="null-box"></span>
                        <p class="right">
                            <span class="txt">{{$t("myWallet.amountFrozen")}} </span>
                            <span class="price">{{walletData.block}}{{$t("comonTxt.money")}}</span>
                        </p>
                    </div>
                    <span class="felx-record" @click="goPath('/my/WithdrawalRecord')">{{$t("myWallet.withdrawalRecord")}}</span>
                </div>
            </div>
            <div class="mian-table">
                <p class="title">{{$t("myWallet.paymentDetails")}}</p>
                <div class="nodata" v-if="walletList.length == 0">
                    <img src="@/assets/img/data-null.png" />
                </div>
                <div class="table" :class="{'tablenodata':walletList.length ==0}" id="DivTable">
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            :finished-text='$t("myWallet.nomore")'
                            @load="onLoad"
                            >
                            <div class="table-item" v-for="(item,index) in walletList" :key="index" >
                                <p class="order-price">{{$t("myWallet.orderPrice",{price:item.orderPayMoney})}}</p>
                                <div class="order-timeorCashback">
                                    <span class="time">{{$t("myWallet.orderCreatedTime")}}{{getLocalMinutesTime(item.orderPaySuccessDt)}}</span>
                                    <p class="price">
                                        <span class="txt">{{$t("myWallet.cashBack")}}+</span>
                                        <span class="num">{{item.money}}</span>
                                        <i class="icon"></i>
                                    </p>
                                </div>
                                <span class="lables " :class="'color'+item.status" >
                                    {{item.status==130001?$t("myWallet.freezing"):item.status==130002?$t("myWallet.closed"):item.status==130003?$t("myWallet.canWithdraw"):item.status==130004?$t("myWallet.Withdrawal"):$t("myWallet.haveWithdrawal")}}
                                </span>
                            </div>
                    </van-list>
                </div>

            </div>
        </van-pull-refresh>
    </div>
</template>
<script>
import { moneyStat, moneyQuery } from "@/api/shop";
import { getLocalMinutesTime } from "@/utils/date";
export default {
    data(){
        return{
            loading:false,
            isLoading:false,
            finished:false,
            walletData:{},
            refreshdisabled:false,
            pageSize:10,
            pageNum:1,
            walletList:[],
            getLocalMinutesTime:getLocalMinutesTime
        }
    },
    created(){
        this.getWalletData();
        this.getWalletList();
        document.title = this.$t("myWallet.name");
    },
    mounted(){
        // console.log(DivTable);
        DivTable.addEventListener("scroll",(e)=>{
            if(e.target.scrollTop > 0){
                this.refreshdisabled = true;
            }else{
                this.refreshdisabled = false;
            }
        })
    },
    methods:{
        async getWalletData(){
            let datadd = {};
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await moneyStat(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                this.walletData = result.data.data;
            }else{
                this.walletData = {};
            }
        },
        async getWalletList(){
            let datadd = {
                pageSize:this.pageSize,
                pageNum:this.pageNum
            }
            let result = await moneyQuery(datadd);
            console.log(result.data.data.data);
            if(result.data.errorCode == 0){
                if(this.pageNum == 1){
                    this.walletList = result.data.data.data;
                }else{
                    result.data.data.data.map(item=>{
                        this.walletList.push(item);
                    })
                }
                if(result.data.data.data.length<this.pageSize){
                    this.finished = true;
                }
            }else{
                this.walletList = [];
            }
        },
        async onLoad(){
            if(!this.finished){
                // 加载状态结束
               this.pageNum++;
               await this.getWalletList();
               this.loading = false;
            }
        },
        // 下拉刷新
        async onRefresh(){
            this.pageNum = 1;
            this.getWalletList();
            this.loading = true;
            await this.getWalletData();
            this.loading = false;
            this.isLoading = false;
            this.finished = false;
        },
        goPath(url){
            this.$router.push({
                path:url
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.wallet-main{
    padding: .6rem;
    background: #fff;
    height: 100%;
    padding-bottom: 0;
    box-sizing: border-box;
    .myrefreh{
        .van-pull-refresh__track{
            display: flex;
            flex-direction: column;
        }
    }
    .van-pull-refresh{
        height: 100%;
    }
    .van-pull-refresh__head{
        margin-top: .7rem;
    }
    .main-head{
        flex: auto 0 0;
        height: 7.2rem;
        background: url('~@/assets/img/myWallet-back.png') no-repeat;
        background-size: 100% 100%;
        margin: -.4rem;
        // box-sizing: border-box;
        padding: .4rem;
        .head-section{
            height: 100%;
            box-sizing: border-box;
            padding: .7rem 0;
            text-align: center;
            position: relative;
            .felx-record{
                position: absolute;
                font-size: .48rem;
                font-weight: 400;
                color: #4592FC;
                top: .8rem;
                right: 0.02rem;
                line-height: .66rem;
                padding: .12rem .32rem .12rem .54rem;
                background: linear-gradient(270deg, #99C4FF 0%, #BAD7FF 100%);
                box-shadow: 0 .02rem 0 0 rgba(255, 255, 255, 0.88);
                border-radius: 2rem .02rem .02rem 2rem;
            }
            .sect-price-title{
                font-size: .56rem;
                font-weight: 500;
                color: #FFFFFF;
                line-height: .8rem;
            }
            .price-txt{
                font-size: 1.4rem;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 1.8rem;
                margin-top: -.16rem;
                margin-bottom: .3rem;
            }
            .Withdrawal{
                font-size: .72rem;
                line-height: 1rem;
                padding: .2rem .86rem;
                font-weight: 500;
                background: #FFFFFF;
                width:max-content;
                margin: 0 auto;
                border-radius: 2.5rem;
                color: #4290FB;
            }
            .past-records{
                margin-top: .8rem;
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                p{
                    flex: 1;
                }
                .left{
                    text-align: right;
                }
                .right{
                    text-align: left;
                }
                .txt{
                    font-size: .56rem;
                    font-weight: 400;
                    color: #AFD0FF;
                    line-height: .8rem;
                }
                .price{
                    line-height: .8rem;
                    font-size: .56rem;
                    font-weight: 500;
                    margin-left: .2rem;
                    color: #FFFFFF;
                }
                .null-box{
                    width: 0;
                    flex: auto 0 0;
                    height: .7rem;
                    border: .02rem solid #FFE5B2;
                    margin: 0 .8rem;
                }
            }
        }
    }
    .mian-table{
        flex: 1;
        margin-top: .4rem;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .title{
            flex: auto 0 0;
            margin: .4rem 0;
            font-size: .72rem;
            font-weight: 500;
            color: #303133;
            line-height: 1rem;
        }
        .table{
            flex: 1;
            overflow: auto;
        }
        .tablenodata{
            height: 0;
            flex: none;
            .van-list__finished-text{
                display: none;
            }
        }
        .nodata{
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 8rem;
                height: auto;
            }
        }
        .table-item{
            border-radius: .16rem;
            padding: .6rem;
            margin-bottom: .4rem;
            position: relative;
            background: #FFFFFF;
            box-shadow: 0 0.04rem .16rem .02rem rgba(201, 201, 201, 0.5);
            .order-price{
                font-size: .56rem;
                font-weight: 600;
                color: #303133;
                line-height: .8rem;
            }
            .lables{
                position: absolute;
                right: 0;
                top: 0;
                font-size: .4rem;
                font-weight: 500;
                color: #FFFFFF;
                line-height: .56rem;
                background: #fff;
                padding: .1rem .3rem;
                border-radius: 0 .32rem 0 .4rem;
            }
            .color130001{
                background:#7D83AA;
            }
            .color130002{
                background:#EDC578;
            }
            .color130003{
                background:#3589F8;
            }
            .color130004{
                background:#FF6464;
            }
            .color130005{
                background:#C29292;
            }
            .order-timeorCashback{
                display: flex;
                margin-top: .2rem;
                align-items: center;
                justify-content: space-between;
                .time{
                    font-size: .56rem;
                    font-weight: 400;
                    color: #838385;
                    line-height: .8rem;
                }
                .price{
                    font-size: .56rem;
                    font-weight: 500;
                    color: #FF8039;
                    line-height: .8rem;
                    .num{
                        font-size: .72rem;
                    }
                    .icon{
                        width: .28rem;
                        height: .5rem;
                        display: inline-block;
                        margin-left: .3rem;
                        background: url("~@/assets/img/more-icon.png") no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
}
</style>
